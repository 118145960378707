var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          attrs: {
            width: 600,
            title: _vm.squareTitle,
            "ok-text": "确认",
            "cancel-text": "取消"
          },
          on: { cancel: _vm.resetForms, ok: _vm.hideModal },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c(
            "a-form-model",
            _vm._b(
              { ref: "ruleForm", attrs: { model: _vm.params } },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "拍场名称" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "标签名称" },
                    model: {
                      value: _vm.params.periodName,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "periodName", $$v)
                      },
                      expression: "params.periodName"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "拍场日期" } },
                [
                  _c("a-date-picker", {
                    attrs: {
                      dateFormat: "'YYYY-MM-DD",
                      disabledDate: _vm.disabledDate,
                      disabled: _vm.squareId !== "" && _vm.params.status === 0,
                      locale: _vm.locale,
                      placeholder: "请选择日期"
                    },
                    on: { change: _vm.onChangeDatePicker },
                    model: {
                      value: _vm.params.periodTime,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "periodTime", $$v)
                      },
                      expression: "params.periodTime"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "场次时间描述" } },
                [
                  _c("a-input", {
                    staticStyle: { width: "100%" },
                    attrs: { placeholder: "场次时间描述" },
                    model: {
                      value: _vm.params.periodTimeDesc,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "periodTimeDesc", $$v)
                      },
                      expression: "params.periodTimeDesc"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }