<template>
  <div>
    <a-table
        :columns="tableList"
        :pagination="pagination"
        :data-source="list"
        :rowKey="(record, index) => index"
        @change="changePage"
    >
      <div slot-scope="row" slot="squareProductNumSlot">
        <div class="color-blue" @change="handleShowProductList(row)">{{ row.periodNum }}</div>
      </div>
      <div slot-scope="row" slot="action">
        <div>
          <a-button
              size="small"
              type="primary"
              class="setup-btn mt-10"
              @click="handleEvent(row, 'editor')"
          >编辑</a-button>
          <a-popconfirm
              title="确定上架之后无法添加或移除商品,请确保准备好之后在上架？"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleEvent(row, 'upSquare')"
          >
            <a-button
                v-if="row.status === -1"
                size="small"
                type="danger"
                class="setup-btn mt-10 ml-10"
            >上架</a-button>
          </a-popconfirm>
          <a-button
              v-if="row.status === 0"
              :disabled="row.status === 0"
              size="small"
              type="danger"
              class="setup-btn mt-10 ml-10"
          >已上架</a-button>
        </div>
      </div>
    </a-table>
    <SquareEditor ref="tagEditorEl" @success="handleEditor"/>
  </div>
</template>
<script>
import SquareEditor from "@/views/cmsPage/productManage/productSquare/_components/SquareEditor"
import {tableColumnsBySquare} from "@/views/cmsPage/productManage/productSquare/_data"

export default {
  props: ['pagination', 'list'],
  components: {SquareEditor},
  data() {
    return {
      tableList: tableColumnsBySquare
    }
  },
  methods: {
    /** 显示拍品列表 */
    handleShowProductList(row) {

    },
    /** 操作 */
    async handleEvent(row, type) {
      if (type === 'editor') {
        await this.$refs.tagEditorEl.showPopup(row)
      }
      if (type === 'upSquare') {
        this.$loading.show()
        const res = await this.axios("/dq_admin/productAuctionPeriod/distributePeriod", {
          params: {
            periodId: row.id
          },
        });
        this.$loading.hide()
        if (res.status != 200) return
        this.$message.info(res.message)
        this.$emit('success')
      }
    },
    /** 修改好了 */
    handleEditor() {
      this.$emit("success");
    },
    /** 翻页 */
    changePage(page) {
      this.$emit("changePage", page);
    },
  }
}
</script>
<style lang="scss" scoped>
.icon-box {
  width: 100px;
  height: 100px;
}
.img-null {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ccc;
}
.itemIcon {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.color-red {
  color: red;
}
.color-blue {
  color: #2d8cf0;
  cursor: pointer;
}
.mt-10 {
  margin-top: 10px;
}
.ml-10 {
  margin-left: 10px;
}
</style>