var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          columns: _vm.tableList,
          pagination: _vm.pagination,
          "data-source": _vm.list,
          rowKey: function(record, index) {
            return index
          }
        },
        on: { change: _vm.changePage },
        scopedSlots: _vm._u([
          {
            key: "squareProductNumSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "div",
                  {
                    staticClass: "color-blue",
                    on: {
                      change: function($event) {
                        return _vm.handleShowProductList(row)
                      }
                    }
                  },
                  [_vm._v(_vm._s(row.periodNum))]
                )
              ])
            }
          },
          {
            key: "action",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "div",
                  [
                    _c(
                      "a-button",
                      {
                        staticClass: "setup-btn mt-10",
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.handleEvent(row, "editor")
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "a-popconfirm",
                      {
                        attrs: {
                          title:
                            "确定上架之后无法添加或移除商品,请确保准备好之后在上架？",
                          "ok-text": "Yes",
                          "cancel-text": "No"
                        },
                        on: {
                          confirm: function($event) {
                            return _vm.handleEvent(row, "upSquare")
                          }
                        }
                      },
                      [
                        row.status === -1
                          ? _c(
                              "a-button",
                              {
                                staticClass: "setup-btn mt-10 ml-10",
                                attrs: { size: "small", type: "danger" }
                              },
                              [_vm._v("上架")]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    row.status === 0
                      ? _c(
                          "a-button",
                          {
                            staticClass: "setup-btn mt-10 ml-10",
                            attrs: {
                              disabled: row.status === 0,
                              size: "small",
                              type: "danger"
                            }
                          },
                          [_vm._v("已上架")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ])
            }
          }
        ])
      }),
      _c("SquareEditor", {
        ref: "tagEditorEl",
        on: { success: _vm.handleEditor }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }