<template>
  <div>
    <a-modal
        :width="600"
        v-model="show"
        :title="squareTitle"
        ok-text="确认"
        cancel-text="取消"
        @cancel="resetForms"
        @ok="hideModal"
    >
      <a-form-model
          ref="ruleForm"
          :model="params"
          v-bind="layout"
      >
        <a-form-model-item label="拍场名称">
          <a-input
              v-model="params.periodName"
              placeholder="标签名称"
          />
        </a-form-model-item>
        <a-form-model-item label="拍场日期">
          <a-date-picker
              v-model="params.periodTime"
              @change="onChangeDatePicker"
              dateFormat="'YYYY-MM-DD"
              :disabledDate="disabledDate"
              :disabled="squareId !== '' && params.status === 0"
              :locale="locale"
              placeholder="请选择日期"
          />
        </a-form-model-item>
        <a-form-model-item label="场次时间描述">
          <a-input
              v-model="params.periodTimeDesc"
              style="width: 100%;"
              placeholder="场次时间描述"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import moment from "moment"
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
export default {
  props: ["detail"],
  data() {
    return {
      locale,
      dateString: undefined,
      defaultDateValue: '',
      dateNew: new Date(),
      show: false,
      params: {
        id: undefined,
        periodName: undefined,
        periodTime: undefined,
        sort: undefined,
        periodTimeDesc: '20:00起截拍'
      },
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
      squareTitle: '新增',
      squareId: ''
    };
  },
  methods: {
    moment,
    /** 禁止选择的日期 */
    disabledDate(current) {
      let hours = this.dateNew.getHours()
      if (hours >= 17) {
        return current < moment().subtract(1,'days')
        // return current && (moment(current).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') || current < moment().subtract('day'))
      } else {
        return current < moment().subtract(1,'days')
      }
    },
    /** 选则日期 */
    onChangeDatePicker(date, dateString) {
      this.dateString = dateString
    },
    async showPopup(data) {
      this.resetForms()
      if (data) {
        this.squareId = data.id
        this.squareTitle = '编辑'
        await this.setDetail(data)
      }
      this.show = true
    },
    async setDetail(data) {
      this.dateString = data.periodTime
      this.params = await Object.assign(this.params, data)
    },
    hideModal() {
      if (!this.params.periodName) return this.$message.warn('场次名称不能为空')
      if (!this.dateString) return this.$message.warn('场次时间不能为空')
      if (!this.params.periodTimeDesc) return this.$message.warn('场次时间描述不能为空')
      this.params.periodTime = this.dateString
      this.submit(this.params)
    },
    async submit(forms) {
      const sendObj = {
        ...forms
      };
      const res = await this.axios.post(
          "/dq_admin/productAuctionPeriod/addOrEdit",
          sendObj
      );
      if (res.status != 200) return;
      this.$message.success(res.message);
      this.resetForms()
      this.$emit("success");
    },
    resetForms() {
      this.params = {
        id: undefined,
        periodName: undefined,
        periodTime: undefined,
        sort: undefined,
        periodTimeDesc: '20:00起截拍'
      }
      this.defaultValueDate = undefined
      this.dateString = undefined
      this.show = false
    },
  },
};
</script>

<style lang="scss" scoped>
.preview-image {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-20%, -50%);
  z-index: 1001;
  padding: 32px 16px;
  background: #f2f2f2;
  border-radius: 8px;
  text-align: center;
  height: 80vh;
  img {
    height: 100%;
  }
  .preview-image-close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 8px;
    cursor: pointer;
  }
}
</style>
